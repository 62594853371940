import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import Button from '@mui/material/Button';

const useStyles = makeStyles(
  ({ typography }) => ({
    root: {
      borderRadius: 30,
      padding: [[16, 28]],
      fontSize: typography.pxToRem(16),
    },
  }),
  { name: 'ButtonXL' },
);

export default function ButtonXL(props) {
  const { className, rootComponent: Component = Button, ...rest } = props;
  const classes = useStyles(props);
  return <Component className={classNames(classes.root, className)} size="large" {...rest} />;
}
