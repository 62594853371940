import loadable from '@loadable/component';

import PlayerLoader from './PlayerLoader';

export { default as AspectRatioWrapper } from './AspectRatioWrapper';
export { default as PlayerLoader } from './PlayerLoader';

export default loadable(() => import(/* webpackChunkName: "player" */ './Player'), {
  fallback: <PlayerLoader />,
});
