import { entities } from 'my-core/my-utils';

import betterGradesImg from 'images/artwork/blurb_better-grades.svg';
import educatorsImg from 'images/artwork/blurb_educators.svg';
import learningAlt1Img from 'images/artwork/blurb_learning-alt1.svg';
import learningImg from 'images/artwork/blurb_learning.svg';
import saveTimeImg from 'images/artwork/blurb_save-time.svg';
import tailoredImg from 'images/artwork/blurb_tailored.svg';
import universityAltImg from 'images/artwork/blurb_university-alt.svg';
import biancaImg from 'images/testimonials/bianca.jpg';
import bilalImg from 'images/testimonials/bilal.jpg';
import eishaImg from 'images/testimonials/eisha.jpg';
import emilyImg from 'images/testimonials/emily.jpg';
import emmaP from 'images/testimonials/emma-p.jpg';
import ethelImg from 'images/testimonials/ethel.jpg';
import gurveerK from 'images/testimonials/gurveer-k.jpg';
import mariaImg from 'images/testimonials/maria.jpg';
import ryconImg from 'images/testimonials/rycon.jpg';

export function getLandingIntroVideo(isCanadian) {
  return {
    videoId: `homepage-intro-${isCanadian ? 'ca' : 'us'}`,
    urls:
      isCanadian ?
        {
          hls: 'https://d3rw207pwvlq3a.cloudfront.net/shared/homepage-intro/v2_CA/hlsPlaylist.m3u8',
          original: 'https://d3rw207pwvlq3a.cloudfront.net/shared/homepage-intro/v2_CA/original/wize-intro-CA.mp4',
        }
      : {
          hls: 'https://d3rw207pwvlq3a.cloudfront.net/shared/homepage-intro/v2_US/hlsPlaylist.m3u8',
          original: 'https://d3rw207pwvlq3a.cloudfront.net/shared/homepage-intro/v2_US/original/wize-intro-US.mp4',
        },
  };
}

export const quotes = {
  grade: { stat: '98%', label: 'Achieved a grade increase' },
  recommend: { stat: '91%', label: 'Would recommend Wizeprep to a friend' },
  time: { stat: '94%', label: 'Reported Wizeprep saved them time' },
};

const emma = {
  testimonial:
    'I really like working through the simplified course and having practice questions. My scores really improved on tests.',
  author: 'Emma P',
  school: 'UBC',
  imageSrc: emmaP,
};
const bianca = {
  testimonial: 'Wizeprep explains concepts in a very concise way that is really easy to understand.',
  author: 'Bianca L',
  school: 'McMaster',
  gradeLevel: 'Undergraduate Student',
  imageSrc: biancaImg,
  videoSrc: {
    hls: 'https://d3rw207pwvlq3a.cloudfront.net/shared/student-testimonials/bianca/hlsPlaylist.m3u8',
    original:
      'https://d3rw207pwvlq3a.cloudfront.net/shared/student-testimonials/bianca/original/Bianca_Long_Testimonial.mp4',
  },
};
const gurveer = {
  testimonial:
    'Wizeprep further explains the material taught by professors in lectures in simple and understandable terms.',
  author: 'Gurveer K',
  school: 'U of T',
  imageSrc: gurveerK,
  gradeLevel: 'University Student',
};

const emily = {
  testimonial:
    'I feel so much more confident when taking my exams, and the extra practice questions truly help me understand the material instead of just memorizing it.',
  testimonialLong:
    'Wizeprep has been my saving grace throughout my first year of university. My grades have gone up significantly. I feel so much more confident when taking my exams, and the extra practice questions truly help me understand the material instead of just memorizing it.',
  author: 'Emily',
  gradeLevel: 'Undergraduate Student',
  imageSrc: emilyImg,
  school: 'U of A',
  videoSrc: {
    hls: 'https://d3rw207pwvlq3a.cloudfront.net/shared/student-testimonials/emily/hlsPlaylist.m3u8',
    original:
      'https://d3rw207pwvlq3a.cloudfront.net/shared/student-testimonials/emily/original/Emily_Long_Testimonial.mp4',
  },
};

const maria = {
  testimonial:
    'I was really struggling with my class in macroeconomics. Then, in my study group, someone talked about Wizeprep and how it helped them. To my surprise, the tutor really explained my econ class in such a way that I finally understood! I am very grateful for Wizeprep.',
  author: 'Maria',
  gradeLevel: 'Undergraduate Student',
  imageSrc: mariaImg,
  videoSrc: {
    hls: 'https://d3rw207pwvlq3a.cloudfront.net/shared/student-testimonials/maria/hlsPlaylist.m3u8',
    original:
      'https://d3rw207pwvlq3a.cloudfront.net/shared/student-testimonials/maria/original/Maria_Long_Testimonial.mp4',
  },
};

const ethel = {
  testimonial:
    'Not only does Wizeprep provide clear and concise lessons for almost all of my courses, but I have access to these lessons 24/7 and can always revisit them when needed!',
  author: 'Ethel',
  gradeLevel: 'High School Student',
  imageSrc: ethelImg,
  videoSrc: {
    hls: 'https://d3rw207pwvlq3a.cloudfront.net/shared/student-testimonials/ethel/hlsPlaylist.m3u8',
    original:
      'https://d3rw207pwvlq3a.cloudfront.net/shared/student-testimonials/ethel/original/Ethel_Long_Testimonial.mp4',
  },
};

const rycon = {
  testimonial: 'Wizeprep’s course-specific videos and practice questions helped me to get through University Calculus.',
  testimonialLong:
    'Wizeprep’s course-specific videos and practice questions helped me to get through University Calculus and I ended up getting an A-.',
  author: 'Rycon',
  gradeLevel: 'Undergraduate Student',
  school: 'U of A',
  imageSrc: ryconImg,
  videoSrc: {
    hls: 'https://d3rw207pwvlq3a.cloudfront.net/shared/student-testimonials/rycon/hlsPlaylist.m3u8',
    original:
      'https://d3rw207pwvlq3a.cloudfront.net/shared/student-testimonials/rycon/original/Rycon_Long_Testimonial.mp4',
  },
};

const eisha = {
  testimonial:
    'It is unexpectedly identical to the content that I am learning in school. It feels like each lesson is custom-made for me.',
  author: 'Eisha',
  gradeLevel: 'High School Student',
  imageSrc: eishaImg,
};

const sarab = {
  testimonial:
    'The Wizeprep review session taught me everything I needed to know. The instructor summarized everything clearly and showed us how to tackle many different exam questions.',
  author: 'Sarab',
  gradeLevel: 'University Student',
  school: 'Western',
};
const natalie = {
  testimonial:
    'My Wizeprep prof showed me everything we had to know for the exam. I really like that she dug through many past exams to find common questions that are likely to show up on my exam. She provided tons of tips and the course was completely worth it.',
  author: 'Natalie',
  gradeLevel: 'University Student',
  school: 'UBC',
};
const sarah = {
  testimonial:
    'My Wizeprep prof made the math seem very easy! She was thorough and simplified everything. Wizeprep is the reason why I passed my exam!',
  author: 'Sarah',
  gradeLevel: 'University Student',
  school: 'Western',
};
const harris = {
  testimonial:
    'My Wizeprep prof used easy to understand analogies that were extremely helpful. I loved having all of those tips in the PDF booklet',
  author: 'Harris',
  gradeLevel: 'University Student',
  school: 'UBC',
};
const nathalia = {
  testimonial:
    'The Wizeprep live prep session was very helpful and my instructor actually EXPLAINED every key concept and followed them with exam-like questions.',
  author: 'Nathalia',
  gradeLevel: 'University Student',
  school: 'Western',
};
const bilal = {
  testimonial: 'Wizeprep helped me save time and stress by preparing me for the exam and I highly recommend it.',
  author: 'Bilal',
  gradeLevel: 'University Student',
  school: 'Western',
  imageSrc: bilalImg,
};

export const testimonials = [emma, bianca, gurveer];
export const testimonialsHomepage = [emily, bianca, maria, ethel, rycon];
export const testimonialsHomepageBlurbs = [emily, maria, bilal];
export const testimonialsStudyPass = [bianca, eisha, rycon, ethel, emily, gurveer];
export const testimonialsLivePrep = [sarab, natalie, sarah, harris, nathalia];
export const testimonialsMCAT = [
  {
    testimonial:
      'I have learned so much more in an hour of watching these MCAT videos than I have in a couple of days of reading my book!',
    author: 'Miriam',
  },
  {
    testimonial:
      'Wizeprep has been extremely helpful for me when studying for the MCAT. They are here to support you from the time you sign up all the way to the end!',
    testimonialLong:
      'Wizeprep has been extremely helpful for me when studying for the MCAT. I really appreciate the one-on-one approach that Wizeprep offers, because they are here to support you from the time you sign up all the way to the end!  I debated a lot about what resources to use for the MCAT and I am glad I did not choose another course or go with textbooks.',
    author: 'Iffah',
  },
  {
    testimonial:
      "I started with a diagnostic of 486! I only dreamt of breaking 500 and I truly believe it was Wizeprep that brought me up to where I am now. I'm so happy I came across this course.",
    testimonialLong:
      "I started with a diagnostic of 486! I only dreamt of breaking 500 and I truly believe it was Wizeprep that brought me up to where I am now. Wizeprep helped me turn daunting topics into concise, digestible, and organized material that I can grasp much easier and apply to my studies. I'm so happy I came across this course.",
    author: 'Vanessa',
  },
  {
    testimonial:
      'I found that this course was really helpful. I really appreciate how the instructor took the time to create detailed answers to the passage questions.',
    testimonialLong:
      'I found that this course was really helpful. I really appreciate how the instructor took the time to create detailed answers to the passage questions, and I found their techniques very helpful in highlighting the passages and going through the process of elimination.',
    author: 'Sonya',
  },
  {
    testimonial:
      'The questions were excellent and so were the explanations. I think the layout and execution of the course was great and very thorough.',
    author: 'Haleh',
  },
  {
    testimonial:
      "Very well done and would definitely recommend! The Wizeprep Profs answer quickly and thoroughly, it's been very helpful! I feel a lot more confident!",
    author: 'Jenna',
  },
];

export const blurbs = {
  educators: {
    header: 'Expert Instructors',
    subheader: 'Our instructors have years of experience as TAs and tutors helping students get better grades.',
    source: educatorsImg,
  },
  time: {
    header: 'Time Saving',
    subheader: 'We take the guess work out of studying. 94% of students say Wizeprep saves them time when studying.',
    source: saveTimeImg,
  },
  course: {
    header: 'Customized Content',
    subheader: 'We customize our lessons to your course to make what you’re learning in class easy to understand.',
    source: tailoredImg,
  },
  learn: {
    header: 'Made for Learning',
    subheader: 'We don’t just give out answers, we guide students of all levels to foundational understanding.',
    source: learningImg,
  },
  better_grades: {
    header: 'Get Better Grades',
    subheader: `98% of students who study with Wizeprep reported higher${entities.noBreakSpace}grades`,
    source: betterGradesImg,
  },
  understand: {
    header: 'Really Understand Concepts',
    subheader: `Our instructors know how to make complex topics feel${entities.noBreakSpace}simple`,
    source: learningAlt1Img,
  },
  optimize: {
    header: `Cut Your Study Time in${entities.noBreakSpace}Half`,
    subheader: `Quick, curated lessons allow you to focus your study time where it${entities.noBreakSpace}matters`,
    source: universityAltImg,
  },
};

export const wavePath =
  'M0,224L48,202.7C96,181,192,139,288,144C384,149,480,203,576,224C672,245,768,235,864,202.7C960,171,1056,117,1152,90.7C1248,64,1344,64,1392,64L1440,64L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z';

export const getBetterGradesStudents = [maria, rycon, emily];
