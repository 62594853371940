import { makeStyles } from '@mui/styles';

import AspectRatioWrapper from './AspectRatioWrapper';
import OverlayActions from './OverlayActions';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: palette.common.black,
    },
    poster: {
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      position: 'absolute',
      objectFit: 'cover',
    },
  }),
  { name: 'PlayerLoader' },
);

export default function PlayerLoader(props) {
  const classes = useStyles(props);
  const { className, poster } = props;
  return (
    <AspectRatioWrapper className={className} wrapperClassName={classes.root}>
      {poster && <img className={classes.poster} src={poster} />}
      <OverlayActions loading />
    </AspectRatioWrapper>
  );
}
