import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Player from 'my-components/VideoPlayer';

import Dialog from '@mui/material/Dialog';

function ModalVideo({ classes, ModalProps, onClose, open, PlayerProps }) {
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      disableAutoFocus
      onClose={onClose}
      open={open}
      PaperComponent="div"
      {...ModalProps}
    >
      <Player autoPlay {...PlayerProps} />
    </Dialog>
  );
}

ModalVideo.propTypes = {
  ModalProps: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  PlayerProps: PropTypes.object,
};
export default withStyles(({ breakpoints, palette, shape: { borderRadius } }) => ({
  paper: {
    width: '100%',
    maxWidth: '75%',
    borderRadius,
    boxShadow: [[0, 0, 12, palette.primary.background]],
  },
  [breakpoints.down('md')]: {
    paper: { maxWidth: '90%' },
  },
}))(ModalVideo);
