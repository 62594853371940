import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { gsap } from 'gsap';
import PropTypes from 'prop-types';
import { memo, useLayoutEffect, useRef } from 'react';

import Fade from '@mui/material/Fade';

import { faPause, faPlay, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    wrapper: {
      width: '40%',
      height: 0,
      paddingTop: '40%',
      position: 'relative',
      transform: 'translateY(-20px)',
    },
    wrapperInner: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    background: {
      width: '60%',
      height: '60%',
      maxHeight: 120,
      maxWidth: 120,
      opacity: 0,
      backgroundColor: palette.grey[600],
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    background_static: {
      opacity: 0.4,
      '$root:hover &': { opacity: 0.6 },
    },
    loader: { opacity: 0.5 },
    icon: {
      color: palette.grey[300],
      width: '50% !important',
      height: '50% !important',
    },
  }),
  { name: 'OverlayActions' },
);

function OverlayActions(props) {
  const { hasStarted, loading, playing } = props;
  const classes = useStyles(props);

  const playingRef = useRef(null);

  const fixed = !loading && !hasStarted && !playing;

  const prevPlaying = useRef(playing);
  useLayoutEffect(() => {
    if (prevPlaying.current !== playing) {
      gsap.fromTo(playingRef.current, 0.6, { autoAlpha: 0.6, scale: 0 }, { autoAlpha: 0, scale: 1 });
    }
    prevPlaying.current = playing;
  }, [playing]);

  return (
    <div className={classes.root} onContextMenu={e => e.preventDefault()}>
      <div className={classes.wrapper}>
        <div className={classes.wrapperInner}>
          <div className={classNames(classes.background, { [classes.background_static]: fixed })} ref={playingRef}>
            <FontAwesomeIcon className={classes.icon} icon={fixed || playing ? faPlay : faPause} />
          </div>
        </div>
        <Fade appear={false} enter={false} in={loading} timeout={600}>
          <div className={classes.wrapperInner}>
            <div className={classNames(classes.background, classes.loader)}>
              <FontAwesomeIcon className={classes.icon} icon={faSpinner} pulse />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}

OverlayActions.propTypes = {
  loading: PropTypes.bool.isRequired,
  playing: PropTypes.bool,
  hasStarted: PropTypes.bool,
};
export default memo(OverlayActions);
