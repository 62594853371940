import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
  {
    root: {
      position: 'relative',
      width: '100%',
      '&:after': {
        content: '""',
        display: 'block',
        paddingBottom: '56.25%',
      },
    },
    wrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  { name: 'AspectRatioWrapper' },
);

function AspectRatioWrapper(props) {
  const { children, className, wrapperClassName, ...rest } = props;
  const classes = useStyles(props);
  return (
    <div className={classNames(className, classes.root)}>
      <div className={classNames(wrapperClassName, classes.wrapper)} {...rest}>
        {children}
      </div>
    </div>
  );
}

AspectRatioWrapper.propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default AspectRatioWrapper;
